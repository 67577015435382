<template>
    <div class="px-3 py-2">
        <v-form
            ref="forcar"
            lazy-validation
        >
            <b-sidebar id="change_calendar_date" lazy aria-labelledby="sidebar-no-header-title" no-header backdrop width="500px" style="direction:ltr" right title="إضافة ملاحظة" shadow >
                <template #default="{ hide }">
                    <div class="m-1 text-start" style="direction:rtl">
                        <div style="width:140px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                            <span>{{ lang.edit_calendar }}</span>
                        </div>
                        <div @click="hide" id="hideEditCalendarDated" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                            <span>{{lang.close}}</span>
                        </div>
                    </div>
                    <v-divider></v-divider>
                    <v-row style="margin:auto 5px; direction:rtl">
                        <v-col cols="12" style="direction:rtl">
                            <label>{{lang.notes}}</label>
                            <b-form-textarea class="inborder" v-model="description" />
                        </v-col>
                        <v-col cols="12" md="6" sm="12">
                            <label>{{lang.start_date}}</label>
                            <b-input-group>
                            <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="sd.day" :options="daysList"/>
                            <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;border-right:none !important;" v-model="sd.month" :options="monthsList" />
                            <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="sd.year" :options="yearsList" />
                            </b-input-group>
                        </v-col>
                        <!-- <v-col cols="12" md="6" sm="12">
                            <label>{{lang.start_time}}</label>
                            <b-input-group>
                            <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="sd.seconds" :options="secondsList" />
                            <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="sd.minutes" :options="minutesList" />
                            <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="sd.hours" :options="hoursList" />
                            </b-input-group>
                        </v-col> -->
                        <v-col cols="12" md="6" sm="12">
                            <label>{{lang.dead_line}}</label>
                            <b-input-group>
                            <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="ed.day" :options="daysList" />
                            <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;border-right:none !important;" v-model="ed.month" :options="monthsList" />
                            <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="ed.year" :options="yearsList" />
                            </b-input-group>
                        </v-col>
                        <!-- <v-col cols="12" md="6" sm="12">
                            <label>{{lang.deadline_time}}</label>
                            <b-input-group>
                            <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="ed.seconds" :options="secondsList" />
                            <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="ed.minutes" :options="minutesList" />
                            <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="ed.hours" :options="hoursList" />
                            </b-input-group>
                        </v-col> -->
                        <v-col cols="6" style="direction:rtl">
                            <label>{{lang.status}}</label>
                            <b-form-select 
                                class="selborder" 
                                v-model="status"
                                :options="statusList"
                            />
                        </v-col>
                        <v-col cols="6" style="direction:rtl">
                            <label>{{lang.work_name}}</label>
                            <b-form-select 
                                class="selborder" 
                                v-model="userid"
                                :options="userLists"
                            />
                        </v-col>
                    </v-row>
                </template>
                <template #footer="">
                    <div class="d-flex text-light align-items-center px-3 py-2">
                    <b-button type="button" variant="success" @click="editCalendar()" class="ma-2" style="width:100px;">{{lang.save}}</b-button>
                    </div>
                </template>
            </b-sidebar>
            
        </v-form>
    </div>
</template>

<script>
import axios from 'axios'
import {SnotifyPosition} from 'vue-snotify'
export default {
    data(){
        return {
            status: '',
            calid: 0,
            calendarData: {},
            sd: {
                day: 1,
                month: 1,
                year: (new Date()).getFullYear(),
                seconds: '00',
                minutes: (new Date()).getMinutes() < 10 ? "0" + (new Date()).getMinutes() : (new Date()).getMinutes(),
                hours: (new Date()).getHours() < 10 ? "0" + (new Date()).getHours() : (new Date()).getHours(),
            },
            ed: {
                day: (new Date()).getDate(),
                month: (new Date()).getMonth() + 1,
                year: (new Date()).getFullYear(),
                seconds: '00',
                minutes: (new Date()).getMinutes() < 10 ? "0" + (new Date()).getMinutes() : (new Date()).getMinutes(),
                hours: (new Date()).getHours() < 10 ? "0" + (new Date()).getHours() : (new Date()).getHours(),
            },
            userLists: [],
            statusList: [],
            userid: 0,
            description: '',
        }
    },
    computed: {
        lang: function(){
          if(this.$cookies.get(this.$store.state.LangCooki) != null){
              if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
              return this.$store.state.lang.en;
              }else{
              return this.$store.state.lang.ar;
              }
          }
          else if(this.$store.state.deflang == 'en'){
              return this.$store.state.lang.en;
          }else{
              return this.$store.state.lang.ar;
          }
        },
        sdate: function() {
            return (new Date(this.sd.year,this.sd.month-1, this.sd.day-1)).toISOString().substring(0,10);
        },
        edate: function() {
            return (new Date(this.ed.year,this.ed.month-1, this.ed.day-1)).toISOString().substring(0,10);
        },
      yearsList: function(){
        const start_year = this.$store.state.licenseType.startYear;
        let t = [{text: this.lang.year, value: -1}];
        for(let i=0;i<100;i++){
          if(parseInt(start_year) + i <= (new Date()).getFullYear()){
            t.push({text: start_year + i, value: start_year+i})
          }
          
        }
        return t
      },
      daysList: function(){
        return [
          {text: this.lang.day, value: -1},
          {text: 1, value: 1},
          {text: 2, value: 2},
          {text: 3, value: 3},
          {text: 4, value: 4},
          {text: 5, value: 5},
          {text: 6, value: 6},
          {text: 7, value: 7},
          {text: 8, value: 8},
          {text: 9, value: 9},
          {text: 10, value: 10},
          {text: 11, value: 11},
          {text: 12, value: 12},
          {text: 13, value: 13},
          {text: 14, value: 14},
          {text: 15, value: 15},
          {text: 16, value: 16},
          {text: 17, value: 17},
          {text: 18, value: 18},
          {text: 19, value: 19},
          {text: 20, value: 20},
          {text: 21, value: 21},
          {text: 22, value: 22},
          {text: 23, value: 23},
          {text: 24, value: 24},
          {text: 25, value: 25},
          {text: 26, value: 26},
          {text: 27, value: 27},
          {text: 28, value: 28},
          {text: 29, value: 29},
          {text: 30, value: 30},
          {text: 31, value: 31},
        ]
      },
      monthsList: function(){
        return [
          {text: this.lang.chose_month, value: -1},
          {text: 1, value: 1},
          {text: 2, value: 2},
          {text: 3, value: 3},
          {text: 4, value: 4},
          {text: 5, value: 5},
          {text: 6, value: 6},
          {text: 7, value: 7},
          {text: 8, value: 8},
          {text: 9, value: 9},
          {text: 10, value: 10},
          {text: 11, value: 11},
          {text: 12, value: 12},
        ]
      },
      secondsList: function(){
        let t = [];
        for(let i=0;i<60;i++){
            let j = i;
            t.push({
                text: j < 10 ? "0"+j : j,
                value: j < 10 ? "0"+j : j
            })
        }
        return t;
      },
      minutesList: function(){
        let t = [];
        for(let i=0;i<60;i++){
            let j = i;
            t.push({
                text: j < 10 ? "0"+j : j,
                value: j < 10 ? "0"+j : j
            })
        }
        return t;
      },
      hoursList: function(){
        // console.log("aaaa" , (new Date()).getHours());
        let t = [];
        for(let i=0;i<24;i++){
            let j = i;
            t.push({
                text: j < 10 ? "0"+j : j,
                value: j < 10 ? "0"+j : j
            })
        }
        return t;
      }
    },
    created(){
        this.getUsers();
        this.getStatus('Calendar');
    },
    methods:{
        async editCalendar(){
            if(this.description == ''){
                let message = 'الملاحظة يجب لا تكون فارغة';
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type","editCalendar")
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[id]',this.calid)
            post.append('data[userid]',this.userid)
            post.append("data[start_date]",this.sdate)
            post.append("data[deadlin]",this.edate)
            post.append("data[description]",this.description)
            post.append("data[status]",this.status)
            const response = await axios.post(this.$store.state.SAMCOTEC.r_path,post);
            if(response && response.data && response.data.results){
                // console.log(response.data.results);
                let message = 'تم التعديل / اضافة الملاحظة بنجاح';
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                this.$parent.$parent.$parent.getCurrentCards();
                document.getElementById('hideEditCalendarDated').click();
            }
        },
        async getUsers(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type" , "getEmpsS");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[status]',1);
            const response = await axios.post(this.$store.state.SAMCOTEC.r_path,post);
            if(response && response.data && response.data.results && response.data.results.data && response.data.results.data.results){
                const res = response.data.results.data.results;
                // console.log("asdfas",res);
                for(let i = 0;i < res.length;i++){
                    this.userLists.push({text: res[i].employee_name, value: res[i].id})
                    this.task_to = res[0].id;
                }
            }
        },
        getStatus(status){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type" , 'getStaticOptions');
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append('data[phrase]',status);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data.results.data;
                    for(let i=0;i<res.length;i++){
                        this.statusList.push({text: res[i][`name_`+this.lang.langname], value: res[i].value})
                    }
                }
            )
        },
        async getCurrentCards(){
           
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            let cook = this.$cookies.get(this.$store.state.COOKIEPhase);
            post.append('type',"getCalendar");
            post.append('auth',cook)
            post.append('lang',this.$cookies.get(this.$store.state.LangCooki))
            post.append('data[id]',this.calid)
            const response = await axios.post(this.$store.state.SAMCOTEC.r_path,post)
            // console.log(response.data.results.data)
            if(response && response.data && response.data.results && response.data.results.data){
                const res = response.data.results.data;
                this.calendarData = res;
                this.userid = this.calendarData.rows[0].userid;
                this.status = this.calendarData.rows[0].status;
                const d = new Date(this.calendarData.rows[0].start_date)
                
                this.sd.day = d.getDate() ;
                this.sd.month = d.getMonth() + 1;
                this.sd.year = d.getFullYear();
                const y = new Date(this.calendarData.rows[0].deadline)
               
                this.ed.day = y.getDate() ;
                this.ed.month = y.getMonth() + 1;
                this.ed.year = y.getFullYear();
            }
        }
    }
}
</script>

<style>
.rowDiv{
    width:48%;
    margin-inline-end: 1%;
    padding:5px;
}
.rowDivFull{
    width:98%;
    margin-inline-end: 2%;
    margin-inline-start: 1%;
    padding:5px;
}
.myBtn{
    flex-wrap: wrap;
    display:flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: stretch;
    margin-top:5px;
}
.mySubBtn{
    flex-wrap: wrap;
    display:none;
    flex-direction: row;
    justify-content: start;
    justify-items: center;
    align-items: center;
    margin-top:5px;
}
.btnRow{
    min-width:100px;
    padding:5px 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
    margin-inline-end:5px;
    border:2px solid #fff;
    cursor: pointer;
    font-weight: bolder;
    overflow-anchor: none;
    border-radius: 5px;
    margin-top:10px;
    margin-bottom:10px;
    margin-inline-end:10px !important;
}
.btnRowF{
    min-width:120px;
    padding:5px 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
    margin-inline-end:5px;
    border:2px solid #fff;
    cursor: pointer;
    overflow-anchor: none;
    border-radius: 5px;
    margin-top:10px;
    margin-bottom:10px;
}
.c_blue{
    background:blue !important;
    color:#fff !important;
}
.c_darkblue{
    background:darkblue !important;
    color:#fff !important;
}
.c_green{
    background:green !important;
    color:#fff !important;
}
.c_red{
    background:red !important;
    color:#fff !important;
}
.c_darkslategrey{
    background:darkslategrey !important;
    color:#fff !important;
}
.c_darkgreen{
    background:darkgreen !important;
    color:#fff !important;
}
.c_darkcyan{
    background:darkcyan !important;
    color:#fff !important;
}
.c_darkolivegreen{
    background:darkolivegreen !important;
    color:#fff !important;
}
.c_darkslateblue{
    background:darkslateblue !important;
    color:#fff !important;
}
.c_darkorange{
    background:darkorange !important;
    color:#fff !important;
}
.c_darkorchid{
    background:darkorchid !important;
    color:#fff !important;
}
.c_lightgray{
    background:#d1d1d1 !important;
    color:#000 !important;
}
.c_black{
    background:#000 !important;
    color:#fff !important;
}
.c_lightgray i{
    display:none;
}
</style>